.host {
  margin: 8px 0;
  padding: 0 3.5em;
  background-color: #fafafa;
  border: 1px solid #e2e2e2;

  @media (max-width: 767px) {
    padding: 0 30px;
  }
}

.hostTitle {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1.33333px;
  color: black;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.amountWrapper {
  font-size: 20px;
  max-width: 240px;
  margin: 8px auto 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentFailedBadge {
  margin: 16px auto 0;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.9375px;
  text-transform: uppercase;
  color: #fff;
  background-color: lightcoral;
  border-radius: 9px;
  max-width: 130px;
  padding: 0 5px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.button {
  display: flex;
  align-items: center;
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }

  & img {
    margin-right: 8px;
  }
}

.sliderInfo {
  position: relative;
  display: flex;
  padding: 20px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -49px;
    height: 1px;
    width: calc(100% + 98px);
    background-color: #e2e2e2;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.sliderInfoBlock {
  flex: 0 0 50%;
  padding: 0 11%;

  @media (max-width: 767px) {
    padding: 0;
  }
}

.sliderInfoLine {
  line-height: 30px;
  display: flex;
  justify-content: space-between;

  & > span {
    color: #818285;
    letter-spacing: 1.33333px;
    font-weight: 500;
  }
}
