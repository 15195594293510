.title {
  font-family: 'Museo', sans-serif;
  font-weight: 600;
  letter-spacing: 1.3px;
  font-size: 32px;
  color: black;
  margin: 0;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}

.logo {
  width: 80%;
  display: flex;
  align-items: center;
}

.code {
  height: 150px;
  & img {
    width: auto;
    height: 100%;
  }
}

.readableCode {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 0;
}

.logo > img {
  width: 70%;
}

.subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 10px;
}

.titleBox {
  margin-bottom: 15px;
}

.thead td {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  border: none !important;
  background-color: white !important;
}

.table td {
  position: relative;
  text-align: center;
  border: 3px solid #e6e7e9;
  &:first-child {
    width: 70%;
    text-align: left;
    padding-left: 10px;
  }
}

.cellBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 28px solid #e6e7e9;
  z-index: -1;
  &_large {
    border-width: 40px;
  }
}

.tfoot {
  height: 85px !important;
  line-height: 30px;
  & span {
    font-size: 32px;
  }
}

.tfoot td:first-child {
  background-color: white !important;
  border: none !important;
}

.table {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  border-spacing: 7px;
  border-collapse: separate;
}

.table tr {
  height: 60px;
}

.textRed {
  color: #be1d2c;
  font-weight: bold;
}

.total {
  width: 90%;
  text-align: right;
  margin-bottom: 0;
}

.textSmall {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
}

.thead {
  font-weight: bold;
  text-transform: uppercase;
}

.tbody {
  font-size: 22px;
}

.list {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  list-style: none;
  padding-left: 25px;
  position: relative;
  & li {
    font-size: 18px;
    line-height: 40px;
  }
}

.n1::before {
  content: '1';
  position: absolute;
  left: 0;
  font-weight: bold;
}

.n2::before {
  content: '2';
  position: absolute;
  left: 0;
  font-weight: bold;
}

.n3::before {
  content: '3';
  position: absolute;
  left: 0;
  font-weight: bold;
}

.n4::before {
  content: '4';
  position: absolute;
  left: 0;
  font-weight: bold;
}

.footer {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-left: 25px;
}

.secondPage {
  page-break-before: always;
}

.contacts {
  width: 100%;
  padding: 20px 60px;
  display: flex;
  border: 1px solid #e2e2e2;
  margin-bottom: 50px;
  &__imgBox {
    width: 20%;
    display: block;
    margin: auto;
    &__circle {
      width: 100%;
      margin-bottom: 10px;
    }
    &__title {
      width: 100%;
    }
  }
  &__box {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
    &__title {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 1.3px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    &__contact {
      display: flex;
      color: #525352;
      letter-spacing: 1.3px;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
      & img {
        margin-right: 10px;
      }
      & > div {
        flex-grow: 1;
      }
    }
  }
}

.marginBottom30 {
  margin-bottom: 30px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.marginBottom400 {
  margin-bottom: 400px;
}
