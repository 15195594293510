.container {
  --grid-step: 16px;
  --grid-step-8: calc(var(--grid-step) * 8);
  --grid-step-2: calc(var(--grid-step) * 2);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1fr);
  gap: var(--grid-step-2) var(--grid-step-8);
  grid-template-areas:
    'items1 items1'
    'items2 items2'
    'totals .';
  margin-bottom: 2em;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.items1 {
  grid-area: items1;
  @media (max-width: 767px) {
    & td {
      padding: 13px !important;
    }
  }
}

.items2 {
  grid-area: items2;
}

.totals {
  grid-area: totals;
  @media (max-width: 767px) {
    & > div > div > form > div {
      margin-bottom: 10px;
    }
    & > div > div > form > div > div {
      flex: 0 0 50% !important;
    }
  }
}
