.tag {
  background: #fafafa;
  border-radius: 60px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  font-feature-settings: 'tnum', 'tnum';
  font-size: 16px;
  height: auto;
  line-height: 24px;
  opacity: 1;
  padding: 6px 16px;
  transition: all 0.3s;
  white-space: nowrap;
  @media (max-width: 1600px) {
    line-height: 18px;
  }

  &__small {
    font-size: 14px;
    margin-right: 4px;
    margin-bottom: 5px;
    padding: 2px 12px;
  }
}
