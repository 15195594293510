.grid {
  --grid-step: 16px;
  --grid-step-8: calc(var(--grid-step) * 8);
  --grid-step-2: calc(var(--grid-step) * 2);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1fr);
  gap: var(--grid-step-2) var(--grid-step-8);
  grid-template-areas:
    'status status'
    'customerProfile address'
    'stylingfor categories'
    'sizeandfit sizeandfitcomments'
    'customercomments customercomments'
    'comments comments';

  *:nth-child(1) {
    grid-area: status;
  }
  *:nth-child(2) {
    grid-area: customerProfile;
  }
  *:nth-child(3) {
    grid-area: address;
  }
  *:nth-child(4) {
    grid-area: stylingfor;
  }
  *:nth-child(5) {
    grid-area: categories;
  }
  *:nth-child(6) {
    grid-area: sizeandfit;
  }
  *:nth-child(7) {
    grid-area: sizeandfitcomments;
  }
  *:nth-child(8) {
    grid-area: customercomments;
  }
  *:nth-child(9) {
    grid-area: comments;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
