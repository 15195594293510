.wrapper {
  padding: 0 0 0 20px;
  color: white;
  @media (max-width: 767px) {
    display: none;
  }
}

.logo {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.img {
  height: 20px;
  width: 60%;
  @media (max-width: 767px) {
    width: 80%;
  }
}

.layout {
  background-color: white;
}

.header {
  background: #1a2028;
  color: white;
  font-size: 22px;
  padding-left: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1600px) {
    padding: 0 1em;
  }
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.logoutBlock {
  display: flex;
  align-items: center;
  font-size: 0.8em;
}

.hi {
  margin-right: 3ex;
  @media (max-width: 767px) {
    display: none;
  }
}

.ava {
  height: 2em;
  width: 2em;
  margin-left: 1ex;
  border-radius: 50%;
}
