/* Museo */
@font-face {
  font-family: 'Museo';
  src: local('Museo'), url(../src/fonts/Museo300-Regular.otf) format('opentype');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'Museo';
  src: local('Museo'), url(../src/fonts/Museo500-Regular.otf) format('opentype');
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'Museo';
  src: local('Museo'), url(../src/fonts/Museo700-Regular.otf) format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../src/fonts/Montserrat-Regular.ttf) format('opentype');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../src/fonts/Montserrat-Medium.otf) format('opentype');
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../src/fonts/Montserrat-Bold.otf) format('opentype');
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
