.icon {
  font-size: 50px;
  margin-bottom: 5px;
}

.upload {
  padding: 1em;
  margin-bottom: 2em;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  height: 10em;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input {
  visibility: hidden;
  position: absolute;
  font-size: 1px;
  opacity: 0;
}
