.App {
  min-height: 100vh;
}

body {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

.header {
  background: #1a2028;
  color: white;
  font-size: 22px;
  padding-left: 35px;
}

.logo {
  font-weight: 700;
}

.layout {
  background-color: white;
}
