.colInfo {
  @media (max-width: 767px) {
    & > div > div > div {
      margin-bottom: 10px;
    }
    & > div > div > div > div {
      flex: 0 0 50% !important;
    }
  }
}
