.wrapper {
  padding: 2em 3em;
  @media (max-width: 1600px) {
    padding: 2em 1em;
  }
  @media (max-width: 1200px) {
    padding: 1em;
  }
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.tableRow {
  cursor: pointer;
}

.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.7;
}

.number {
  font-size: 16px;
  font-weight: 600;
}

.stylingFor {
  font-size: 16px;
  width: 10%;
  @media (max-width: 767px) {
    width: 60%;
  }
}

.gender {
  opacity: 0.5;
  width: 6%;
}

.genderGlobal {
  @media (max-width: 767px) {
    display: none;
  }
}

.dateSpan {
  opacity: 0.4;
}

.table {
  thead > tr > th:nth-child(1) {
    padding-left: 16px;
    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  tr > td:nth-child(1) {
    padding: 12px 16px 12px 32px;
    @media (max-width: 1024px) {
      padding-left: 0;
    }
    @media (max-width: 767px) {
      padding-left: 16px;
    }
  }
}

.age {
  width: 6%;
  @media (max-width: 767px) {
    display: none;
  }
}

.lookingFor {
  width: 15%;
  @media (max-width: 992px) {
    display: none;
  }
}

.clothing {
  width: 40%;
  @media (max-width: 1600px) {
    width: 30%;
  }
  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 1480px) {
    display: none;
  }
}

.status {
  width: 8%;
  @media (max-width: 767px) {
    width: 40%;
  }
}

.date {
  width: 9%;
  @media (max-width: 1200px) {
    text-align: center;
  }
  @media (max-width: 767px) {
    display: none;
  }

  white-space: nowrap;
}

.statusSelect {
  width: 100%;
}
