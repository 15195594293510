.header {
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    padding: 0;
    margin-top: 10px;
    align-items: center;
  }

  & h1 {
    margin-bottom: 0;
  }
}

.orderTitle {
  display: flex;
  align-items: center;
}

.salesforceLink {
  margin-left: 16px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
