.green,
.red,
.grey {
  font-size: 18px;
  font-weight: 500;
}

.green {
  color: var(--color-green-6);
}

.red {
  color: var(--color-red-6);
}

.grey {
  color: #868588;
  @media (max-width: 767px) {
    margin-left: 30px;
  }
}

.host {
  margin-top: 19px;
}

.title {
  font-size: 0.9em;
  line-height: 90%;
}
