.buttonsContainer {
  display: flex;
  padding: 0 0 3em;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
}

.buttonRequestCharge {
  display: flex;
  align-items: center;
  margin-right: auto;
  & img {
    margin-right: 10px;
  }
}
