.container {
  width: 960px;
  margin: 2em auto;
  @media (max-width: 992px) {
    width: 750px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 10px;
    margin-top: 0;
  }
}
