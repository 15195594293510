.buttonWrapper {
  @media (max-width: 767px) {
    width: 100%;
    & > div {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
}
.formInput {
  @media (max-width: 767px) {
    padding: 15px 10px;
    width: 100%;
  }
}

.editCustomerProfile input {
  @media (max-width: 767px) {
    padding: 15px 10px;
  }
}

.gender {
  @media (max-width: 767px) {
    display: flex;
    & > div {
      flex: 0 0 50% !important;
    }
  }
}

.status {
  @media (max-width: 767px) {
    & > div {
      height: 54px !important;
    }
    & span {
      line-height: 54px !important;
    }
  }
}

.test {
  @media (max-width: 767px) {
    & > div > div > div > div > div > div > div > div > div {
      padding: 9.5px 6px !important;
    }
    & > div > div > div > div > div > div > div > div > div > div > span {
      height: 27px !important;
      line-height: 25px !important;
    }
  }
}
