.state {
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.9375px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 9px;
  padding: 0 5px;
}

.depositTaken {
  background-color: #7dcf1c;
}

.depositCaptured {
  background-color: black;
}

.depositRefunded {
  background-color: dimgrey;
}

.noDepositTaken {
  background-color: lightgrey;
}
