.infoPrice {
  letter-spacing: 1.3px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.infoBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoBoxText {
  font-size: 12px;
  font-weight: 500;
  &:first-child {
    margin-right: 5px;
  }
}
