.state {
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.9375px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 9px;
  max-width: 130px;
  padding: 0 5px;
}

.stateSubmitted {
  background-color: gold;
}

.stateReadyForPicking {
  background-color: #f5a841;
}

.stateDispatched {
  background-color: #f18744;
}

.stateDelivered {
  background-color: #7dcf1c;
}

.statePendingPayment {
  background-color: #0091ff;
}

.statePaymentOverdue {
  background-color: #f56565;
}

.statePendingReturn {
  background-color: #0069e1;
}

.stateReturnOverdue {
  background-color: #f56565;
}

.stateCompleted {
  background-color: #000;
}

.stateArchived {
  background-color: lightgrey;
}

.stateCancelled {
  background-color: silver;
}

.stateDeliveryOverdue {
  background-color: lightcoral;
}

.stateFullyReturned {
  background-color: grey;
}
