.host {
  display: flex;
  padding: 2em 0 2em;
  justify-content: flex-end;
  @media (max-width: 767px) {
    justify-content: center;
    & button {
      padding: 6.4px 15% !important;
    }
  }
}
