.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.headerBox {
  min-width: 30%;
}

.headerText {
  font-size: 18px;
  margin-top: 40px;
}

.headerTextActive {
  font-weight: 500;
  color: #be1d2c;
}

.invoiceTo {
  border: 2px solid #e6e7e9;
  padding: 16px;
  min-width: 30%;
}

.box {
  padding: 40px 0;
  font-size: 16px;

  & p {
    margin-bottom: 4px;
  }
}

.data {
  padding-bottom: 40px;
  font-size: 16px;
}

.table {
  width: 100%;
}

.tableTotal {
  width: 100%;
  margin-top: 24px;
}

.theadTd {
  color: #be1d2c;
}

.theadTd,
.tbodyTd {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #e6e7e9;
  line-height: 40px;
  padding: 0 8px;

  &:first-child {
    text-align: left;
    padding-left: 8px;
  }

  &:last-child {
    text-align: right;
    padding-right: 8px;
  }
}

.totalTd {
  line-height: 24px;
  font-weight: 700;
  border-color: transparent;

  &:last-child {
    text-align: right;
    padding-right: 8px;
  }
}

.hiddenTd {
  color: transparent;
  border-color: transparent;
  line-height: 16px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
