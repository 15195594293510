.container {
  margin: 1em 0 1em 50px;
  & > tbody > tr > th {
    width: 150px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    opacity: 0.7;
  }
  & > tbody > tr > th,
  & > tbody > tr > td {
    padding: 0.5em 0;
  }
}
