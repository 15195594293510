.buttonWrapper {
  display: flex;
  padding: 0 0 3em;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
}

.button {
  display: flex;
  align-items: center;

  & img {
    margin-right: 8px;
  }
}
