.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.7;
}

.table {
  thead > tr > th:nth-child(1) {
    padding-left: 16px;
  }
  thead > tr > th:nth-child(n + 5) {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tr > td:nth-child(1) {
    padding: 12px 16px 12px 32px;
  }
  tr > td:nth-child(n + 5) {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
