.host {
  margin: 2em 0;
  padding: 0 3.5em;
  background-color: #fafafa;
  border: 1px solid #e2e2e2;

  @media (max-width: 767px) {
    padding: 0 30px;
  }
}

.hostTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1.33333px;
  color: black;
  padding-top: 25px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.inputWrapper {
  padding: 1em 0 3em;
  width: 45%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    flex: 0 0 40%;
  }

  @media (max-width: 992px) {
    width: 60%;
  }

  @media (max-width: 767px) {
    padding: 1em 0 3em;
    width: 100%;
  }
}

.inputCurrency {
  flex: 0 0 10%;
  text-transform: uppercase;
  letter-spacing: 1.33333px;
}

.inputWrapperSpan {
  flex: 0 0 45%;
  letter-spacing: 1.33333px;
  & span {
    font-weight: bold;
    font-size: 15px;
  }
}

.title {
  font-size: 0.5em;
}

.sliderInfo {
  position: relative;
  display: flex;
  padding: 20px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -49px;
    height: 1px;
    width: calc(100% + 98px);
    background-color: #e2e2e2;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.sliderInfoBlock {
  flex: 0 0 50%;
  padding: 0 11%;

  @media (max-width: 767px) {
    padding: 0;
  }
}

.sliderInfoLine {
  line-height: 30px;
  display: flex;
  justify-content: space-between;

  & > span {
    color: #818285;
    letter-spacing: 1.33333px;
    font-weight: 500;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.button {
  display: flex;
  align-items: center;
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }

  & img {
    margin-right: 8px;
  }
}
